import request from './request';

export function search(params) {
  return request('get', '/apiv2/users', params);
}

export function setUserProfile(params) {
  return request('post', '/apiv2/user/update', params);
}

export function deleteAccount() {
  return request('post', '/apiv2/user/delete', {});
}

export function getUserSlug(id) {
  return request('get', `/apiv2/user/${id}/slug`, {});
}

export function getUserProfile() {
  return request('get', '/apiv2/user/get-info', {});
}

export function subscribeToNotification ({ type }) {
  return request('post',`/apiv2/user/notifications/${type}/subscribe`, {});
}

export function unsubscribeToNotification ({ type }) {
  return request('post',`/apiv2/user/notifications/${type}/unsubscribe`, {});
}

export function getMutualFollowers(id) {
  return request('get', `/apiv2/followers/${id}/mutual`, {});
}