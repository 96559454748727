import { defineStore } from 'pinia';
import axios from 'axios';

import { getMutualFollowers, getUserProfile } from '../resources/js/api/users';

const userLoginDataFromLocalStorage = JSON.parse(localStorage.getItem('userLoginData'));

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      userProfileData: null,
      usersFollowed: 0,
      usersWaiting: 0,
      isUserLoginLoading: false,
      isUserLogin: userLoginDataFromLocalStorage ? userLoginDataFromLocalStorage.logged_in === 1 : false,
      userLoginData: userLoginDataFromLocalStorage || null,
      isSocialNetworkSignUp: false,
      chatEnabledStatus: {},
    };
  },
  getters: {
    getIsUserLogin: state => state.isUserLogin,
  },
  watch: {
    userLoginData: {
      handler(userVal) {
        localStorage.setItem('userLoginData', JSON.stringify(userVal));
      },
      deep: true,
    },
  },
  actions: {
    async checkUserLogin(callback) {
      this.isUserLoginLoading = true;
      await axios
        .get('/apiv2/user/check')
        .then(async response => {
          this.isUserLoginLoading = false;
          let loginDate;
          if (response.data.logged_in === 1) {
            loginDate = Date.now().toString();
          }
          localStorage.setItem('userLoginData', JSON.stringify({ ...response.data, loginDate }));

          if (callback) {
            await callback(response);
          }
          this.userLoginData = response.data;
          this.isUserLogin = response.data.logged_in === 1;
        })
        .catch(error => {
          this.isUserLoginLoading = false;
          console.error({ error });
        });
    },
    async getUserProfile() {
      const res = await getUserProfile();
      this.userProfileData = res.user;
      this.usersFollowed = res.followed;
      this.usersWaiting = res.waiting;
    },
    handleSocialNetworkSignUp(value) {
      this.isSocialNetworkSignUp = value;
    },
    async handleChatEnabled(id) {
      if (this.isUserLogin) {
        const res = await getMutualFollowers(id);
        this.chatEnabledStatus = {
          ...this.chatEnabledStatus,
          [id]: res.mutual_followers,
        };
      }
    },
  },
});
